import React from 'react'
import styled from '@emotion/styled'
import colors from '../../styles/colors'


const ResponsiveSvg = styled.svg`

`

const Download = ({ style, className, onClick }) => (
  <ResponsiveSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' css={style} onClick={onClick}
                 className={className}>
    <path d='M336,176h40a40,40,0,0,1,40,40V424a40,40,0,0,1-40,40H136a40,40,0,0,1-40-40V216a40,40,0,0,1,40-40h40'
          style={{
            fill: 'none',
            stroke: colors.offWhite,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}/>
    <polyline points='176 272 256 352 336 272'
              style={{
                fill: 'none',
                stroke: colors.offWhite,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '32px',
              }}/>
    <line x1='256' y1='48' x2='256' y2='336'
          style={{
            fill: 'none',
            stroke: colors.offWhite,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}/>
  </ResponsiveSvg>
)

export default Download
