import Products from '../data/catalog'
import React from 'react'
import colors from '../styles/colors'


export const getStartingPriceMosaic = (material, orientation) => {
  const sizes = Products.mosaic[material][orientation].sizes
  let minSize = sizes[Object.keys(sizes)[0]]
  return typeof minSize === 'object' ? minSize.none : minSize
}

export const getPriceDiffMosaic = (material, orientation, currentSize, size, currentFrame, frame) => {
  const sizes = Products.mosaic[material][orientation].sizes
  const hasFrameOptions = typeof sizes[size] === 'object'
  if (size === currentSize && frame === currentFrame) return `$${hasFrameOptions ? sizes[size][frame] : sizes[size]}`
  else if (!sizes[size] || !sizes[currentSize]) return '...'
  else {
    const newPrice = hasFrameOptions ? sizes[size][frame] : sizes[size]
    const currentPrice = hasFrameOptions ? sizes[currentSize][currentFrame] : sizes[currentSize]
    const diff = newPrice - currentPrice
    return diff > 0 ?
      <span style={{ color: colors.darkGreen }}>{`+$${Math.abs(diff)}`}</span> :
      <span style={{ color: colors.darkPurple }}>{`$${newPrice}`}</span>
  }
}

export const getSizeOptionsMosaic = (material, orientation) => Object.keys(Products.mosaic[material][orientation].sizes)

export const getFrameOptionsMosaic = (material, orientation, size) => {
  let frames = Products.mosaic[material][orientation].sizes[size]
  return typeof frames === 'object' ? Object.keys(frames) : null
}
