const colors = {
    // Brand
    lightGrey: "#8F8E9E",
    darkGrey: "#46485C",
    lightPurple: "#868AEB",
    lightLightPurple: "#E1E4FD",
    darkPurple: "#3B438B",
    offWhite: "#f7f7f7",
    red: "#FF4242",
    successGreen: "#24AE73",
    darkGreen: "#0e8c0e"
}

export default colors;