import React from 'react'
import colors from '../../../styles/colors'
import styled from '@emotion/styled'
import HelpModal from '../../utils/HelpModal'


const HelpTable = styled.table`  
  th {
    white-space: nowrap;
    padding: 5px;
    text-align: center;
  }
  
  td {
    border-top: 1px solid black;
    padding: 5px;
  }
`

const CenterNoWrap = styled.td`
  text-align: center;
  white-space: nowrap;
`


export default () => {
  return <HelpModal
    color={colors.lightPurple}
    title={'Frame'}
    width={'50%'}
    content={
      <p>
        This setting selects whether or not you'd like your mosaic to be framed. Only available for paper and canvas
        options.
        See notes for desired material below for more details! Feel free to <a
        href="https://photoroni.freshdesk.com/support/tickets/new" target="_blank" rel="noopener noreferrer">contact
        us</a> for custom framing options if these don't quite fit your requirements!
        <HelpTable>
          <tr>
            <th>Material</th>
            <th>Notes</th>
          </tr>
          <tr>
            <CenterNoWrap>Canvas</CenterNoWrap>
            <td>
              <ul>
                <li>Roma Moulding - hand crafted in Italy</li>
                <li>5 times sanded & finished without toxins or chipping</li>
                <li>100% real pine wood</li>
                <li>Professional matte black finish</li>
                <li>Ready to hang - pre-installed hanging hardware</li>
                <li>Rubber bumpers - protects wall surface and keeps print straight on the wall</li>
              </ul>
            </td>
          </tr>
          <tr>
            <CenterNoWrap>Paper</CenterNoWrap>
            <td>
              <ul>
                <li>100% real wood frame with a matte black or white enamel finish</li>
                <li>Shatter proof plexi glass</li>
                <li>Depth of frame is 3/4"</li>
                <li>Width of frame is 1"</li>
                <li>Ready to hang - pre-installed hanging hardware</li>
                <li>Rubber bumpers - protects wall surface and keeps print straight on the wall</li>
              </ul>
            </td>
          </tr>
        </HelpTable>
      </p>
    }
  />
}
