import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Uppy from '@uppy/core'
import { IS_DEV } from '../../utils/helpers'
import uuidv4 from 'uuid/v4'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { StepTitle } from './index'
import { API } from 'aws-amplify'
import ProjectSplitSelector from '../project/ProjectSplitSelector'
import ProjectOrientation, { Orientations } from '../project/ProjectOrientation'
import ReactCrop from 'react-image-crop'
import Loader from 'react-spinners/RotateLoader'
import colors from '../../styles/colors'
import 'react-image-crop/dist/ReactCrop.css'
import { DashboardModal } from '@uppy/react'
import { ActionButton } from '../../styles/shared'
import Upload from '../svgs/Upload'
import Constants from '../../data/constants'
import { trackMosaicUploadedBackground } from '../../utils/tracking'
// import Facebook from '@uppy/facebook'


const Container = styled.div`
  display: flex;
  margin: 0 0 3em 0;
`

const Instructions = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`

const PhotoContainer = styled.div`
  margin: 0em .5em .5em .5em;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .ReactCrop__crop-selection {
    border-image-source: none;
  }
  .ReactCrop__image {
    max-height: 500px;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const UploadButton = styled(ActionButton)`
  margin: 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadIcon = styled(Upload)`
  width: 25px;
  margin: 0 .5em;
`


const uppy = Uppy({
  id: 'mosaic-background',
  autoProceed: true,
  debug: IS_DEV,
  restrictions: {
    maxFileSize: Number(Constants.maxImageUploadSize),
    maxNumberOfFiles: 1,
    minNumberOfFiles: 1,
    allowedFileTypes: ['.jpg', '.jpeg', '.png'],
  },
  onBeforeFileAdded: (currentFile, files) => {
    currentFile.meta['s3Key'] = `uploads/${localStorage.getItem('projectId')}/mosaic-background/${uuidv4()}`
  },
}).use(AwsS3Multipart, {
  companionUrl: process.env.GATSBY_COMPANION_URL,
// }).use(Facebook, {
//   companionUrl: process.env.GATSBY_COMPANION_URL
})


const Background = ({ project, saveMosaic }) => {
  const [uppyOpen, setUppyOpen] = useState(false)
  const [crops, setCrops] = useState({
    [Orientations.portrait]: {},
    [Orientations.landscape]: {},
    [Orientations.square]: {},
  })
  const [backgroundInfo, setBackgroundInfo] = useState(null)
  const [loadingNew, setLoadingNew] = useState(false)
  const cropComponent = backgroundInfo ? (
    backgroundInfo.url && backgroundInfo.crops ?
      <PhotoContainer>
        <ReactCrop
          src={backgroundInfo.url}
          crop={crops[project.orientation]}
          onChange={(crop, percentCrop) => setCrops({ ...crops, [project.orientation]: percentCrop })}
          onDragEnd={() => updateCrops()}
          keepSelection={true}
        />
      </PhotoContainer> : <LoaderContainer>
        <h3>No Background Uploaded</h3>
      </LoaderContainer>) : <LoaderContainer>
    <Loader size={20} color={colors.darkPurple}/>
  </LoaderContainer>

  const updateCrops = () => {
    API.put('photoroni', `/projects/${project.project_id}/mosaic/background`, { body: { crops } })
  }

  useEffect(() => {
    API.get('photoroni', `/projects/${project.project_id}/mosaic/background`, {}).then((newBackgroundInfo) => {
      setCrops(newBackgroundInfo.crops)
      setBackgroundInfo(newBackgroundInfo)
    })
  }, [project.project_id])

  useEffect(() => {
    if (loadingNew) {
      trackMosaicUploadedBackground(project.project_id)
      let poll = setInterval(() => {
        API.get('photoroni', `/projects/${project.project_id}/mosaic/background`, {}).then((newBackgroundInfo) => {
          if (newBackgroundInfo.url && newBackgroundInfo.last_updated_seconds < 10) {
            setCrops(newBackgroundInfo.crops)
            setBackgroundInfo(newBackgroundInfo)
            setLoadingNew(false)
            saveMosaic({ orientation: Orientations.portrait })
            clearInterval(poll)
          }
        })
      }, 1000)
    }
  }, [loadingNew, project.project_id, saveMosaic])

  uppy.on('complete', () => {
    uppy.reset()
    setBackgroundInfo(null)
    if (!loadingNew) setLoadingNew(true)
  })

  return <Container>
    <Instructions>
      <StepTitle>Step 1: Select your background photo</StepTitle>
      <p>Click the button below to upload a photo to use as the background for your mosaic. Then, select the orientation
      you want your mosaic to be and adjust the crop box on your uploaded photo to select the portion of the photo you'd
      like to show on the mosaic.</p>
      <UploadButton onClick={() => setUppyOpen(true)}><UploadIcon/>Upload Background</UploadButton>
      <ProjectSplitSelector title="Orientation" keepTabletColumns={true}>
        <ProjectOrientation label="Portrait" widthRatio={1.5} heightRatio={2}
                            selected={project.orientation === Orientations.portrait}
                            onClick={() => saveMosaic({ orientation: Orientations.portrait })}/>
        <ProjectOrientation label="Landscape" widthRatio={2} heightRatio={1.5}
                            selected={project.orientation === Orientations.landscape}
                            onClick={() => saveMosaic({ orientation: Orientations.landscape })}/>
        <ProjectOrientation label="Square" widthRatio={1.75} heightRatio={1.75}
                            selected={project.orientation === Orientations.square}
                            onClick={() => saveMosaic({ orientation: Orientations.square })}/>
      </ProjectSplitSelector>
    </Instructions>
    {cropComponent}
    <DashboardModal
      uppy={uppy}
      open={uppyOpen}
      disablePageScrollWhenModalOpen={false}
      closeModalOnClickOutside={true}
      closeAfterFinish={true}
      onRequestClose={() => setUppyOpen(false)}
      allowMultipleUploads={false}
      // plugins={['Facebook']}
      showLinkToFileUploadResult={false}
    />
  </Container>
}

export default Background
