import React, { useState } from 'react'
import { Modal } from 'antd'
import Help from '../svgs/Help'
import styled from '@emotion/styled'


const HelpIcon = styled(Help)`
  width: 24px;
  margin-left: 5px;
  
  &:hover path, &:hover circle {
    stroke-width: 40 !important;
    cursor: pointer;
  }
`


const HelpModal = ({ color, title, content, className, width }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return <div className={className}>
    <HelpIcon onClick={() => setModalOpen(true)} color={color}/>
    <Modal
      title={title}
      footer={null}
      onCancel={() => setModalOpen(false)}
      visible={modalOpen}
      width={width || 416}
    >
      {content}
    </Modal>
  </div>
}

export default HelpModal
