import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { DashboardModal } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { StepTitle } from './index'
import { API } from 'aws-amplify'
import Loader from '../utils/Loader'
import colors from '../../styles/colors'
import Uppy from '@uppy/core'
import { IS_DEV } from '../../utils/helpers'
import uuidv4 from 'uuid/v4'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { ActionButton } from '../../styles/shared'
import MosaicTileCrop from './MosaicTileCrop'
import Upload from '../svgs/Upload'
import Constants from '../../data/constants'
import { trackMosaicUploadedTiles } from '../../utils/tracking'


const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Instructions = styled.div`
  padding: 10px;
  margin: 5px;
  flex: 1;
  height: 100%;
`

const GalleryContainer = styled.div`
  display: flex;
  flex: 1.5;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2%;
  overflow-y: scroll;
  height: 500px;
  border: 2px solid ${colors.darkPurple};
  border-radius: 5px;
  background: ${colors.offWhite};
  margin: 2% 0%;
`

const UploadButton = styled(ActionButton)`
  margin: 0;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadIcon = styled(Upload)`
  width: 25px;
  margin: 0 .5em;
`

const uppy = Uppy({
  id: 'mosaic-tiles',
  autoProceed: true,
  debug: IS_DEV,
  restrictions: {
    maxNumberOfFiles: Number(Constants.maxMosaicTiles),
    maxFileSize: Number(Constants.maxImageUploadSize),
    allowedFileTypes: ['.jpg', '.jpeg', '.png'],
  },
  onBeforeFileAdded: (currentFile, files) => {
    currentFile.meta['s3Key'] = `uploads/${localStorage.getItem('projectId')}/mosaic-tiles/${uuidv4()}`
  },
}).use(AwsS3Multipart, {
  companionUrl: process.env.GATSBY_COMPANION_URL,
})


const Tiles = ({ project }) => {
  const [tiles, setTiles] = useState([])
  const [uppyOpen, setUppyOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const eventFired = useRef(false)

  useEffect(() => {
    API.get('photoroni', `/projects/${project.project_id}/mosaic/tiles`, {}).then((res) => {
      setTiles(res.tiles)
      setIsLoading(false)
    })
  }, [isLoading, project.project_id])

  uppy.on('complete', () => {
    // Have to do this wacky stuff with the ref because this event fired 5x for some reason
    if (!eventFired.current) {
      eventFired.current = true
      trackMosaicUploadedTiles(project.project_id)
      setTimeout(() => {
        setIsLoading(true)
        eventFired.current = false
      }, 3000)
    }
  })

  return <Container>
    <Instructions>
      <StepTitle>Step 2: Select your tile photos</StepTitle>
      <p>Click the button below to upload photos to use as the tiles in your mosaic. The more the better! Once your tiles are
      uploaded, they'll show up in the box to the right. There, you'll see a square crop box that you can adjust on each. Since your
      tiles need to be squares on the mosaic, you'll need to adjust this box on each of your tiles to select the portion of the photo
      you'd like to show as the tile.</p>
      <UploadButton onClick={() => setUppyOpen(true)}><UploadIcon/>Upload Tiles</UploadButton>
    </Instructions>
    <GalleryContainer>
      {tiles.map((tile) => {
        return <MosaicTileCrop key={tile.key} projectId={project.project_id} backgroundUrl={tile.url} tileKey={tile.key}
                               initialCrop={tile.crop}/>
      })}
      {isLoading ? <Loader/> : <></>}
    </GalleryContainer>
    <DashboardModal
      uppy={uppy}
      open={uppyOpen}
      disablePageScrollWhenModalOpen={false}
      closeModalOnClickOutside={true}
      onRequestClose={() => {
        setUppyOpen(false)
      }}
      // plugins={['Facebook']}
      showLinkToFileUploadResult={false}
    />
  </Container>
}

export default Tiles
