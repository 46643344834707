import React from 'react'
import colors from '../../../styles/colors'
import HelpModal from '../../utils/HelpModal'


export default () => {
  return <HelpModal
    color={colors.lightPurple}
    title={'Blend'}
    width={'50%'}
    content={
      <p>
        This setting adjusts the blend between the tiles and the background.
        <ul>
          <li><b>Strong Tiles</b> means that the tiles will be much more prominent against the background.</li>
          <li><b>Strong Background</b> means that the tiles will appear faded against the background but the
            background will be much clearer
          </li>
        </ul>
        The more tiles you have (either by having a large size or small tiles, or both!), the more you'll be able
        to go toward stronger tiles and have it look good. If you have a small size with medium or large tiles, you'll
        have to go toward stronger background to be able to still see the background well.
        <br/><br/>
        This setting is really a personal preference though -- definitely try out all the options here to see
        what you like most!
      </p>
    }
  />
}
