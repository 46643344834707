import React, { useState } from 'react'
import { API } from 'aws-amplify'
import styled from '@emotion/styled'
import { Input, Modal } from 'antd'
import { useStripe } from '@stripe/react-stripe-js'
import { trackProceededToCheckout, trackSavedProject } from '../../utils/tracking'
import { capitalize } from '../../utils/helpers'
import Mosaic from '../mosaic'
import Save from '../svgs/Save'


const SaveBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 30px;
  width: 200px;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    font-weight: 600;
    svg {
      width: 52px;
    }
  }
`

const SaveIcon = styled(Save)`
  width: 50px;
  padding: 0 10px;
`

const ProjectTypes = {
  'mosaic': Mosaic,
}


export default ({ project }) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const stripe = useStripe()

  const checkout = () => {
    trackProceededToCheckout(project.project_id, project.project_type)
    API.get('photoroni', `/projects/${project.project_id}/checkout`, {}).then(({ session_id }) => {
      const { error } = stripe.redirectToCheckout({ sessionId: session_id })
      if (error) {
        alert(error.message)
      }
    })
  }

  const saveProject = () => {
    // Send Segment identify and track call to trigger Klaviyo email flow
    trackSavedProject(email, project.project_id, project.project_type)
    setSaveModalOpen(false)
  }

  return <>
    <SaveBox onClick={() => setSaveModalOpen(true)}>
      <SaveIcon/> Save
    </SaveBox>
    <Modal
      title={`Save your ${capitalize(project.project_type)}`}
      okText={'Save'}
      okButtonProps={{ disabled: !email.length }}
      visible={saveModalOpen}
      onOk={() => saveProject()}
      onCancel={() => setSaveModalOpen(false)}
    >
      <p>We'll send a link to your email, so that you can come back and work on your {project.project_type} any
        time!</p>
      <Input placeholder={'Email'} type={'email'} onChange={(e) => setEmail(e.target.value)} value={email}
             autocomplete={'email'}/>
    </Modal>
    {React.createElement(ProjectTypes[project.project_type], { initialProject: project, checkout })}
  </>
}
