const klaviyoIdentify = (email) => {
  if (window) {
    let _learnq = window._learnq || [];
    _learnq.push(['identify', { '$email': email }])
  }
}

const klaviyoTrack = (event, payload) => {
  if (window) {
    let _learnq = window._learnq || [];
    _learnq.push(['track', event, payload])
  }
}

const segmentTrack = (event, payload) => {
  if (window) window.analytics.track(event, payload)
}

const segmentIdentify = (email) => {
  if (window) window.analytics.identify({ email })
}

const track = (event, payload) => {
  segmentTrack(event, payload)
  klaviyoTrack(event, payload)
}

export const identifyEmail = (email) => {
  segmentIdentify(email)
  klaviyoIdentify(email)
}

export const trackSavedProject = (email, projectId, projectType) => {
  identifyEmail(email)
  track('Saved Project', { projectId, projectType })
}

export const trackMosaicStep = (projectId, step) => {
  track(`Mosaic Step ${step}`, { projectId, projectType: 'mosaic', step})
}

export const trackMosaicUploadedBackground = (projectId) => {
  track('Mosaic Uploaded Background', { projectId, projectType: 'mosaic'})
}

export const trackMosaicUploadedTiles = (projectId) => {
  track('Mosaic Uploaded Tiles', { projectId, projectType: 'mosaic'})
}

export const trackMosaicModifiedParameters = (projectId, parameterName, parameterValue) => {
  track('Mosaic Modified Parameters', { projectId, projectType: 'mosaic', parameterName, parameterValue})
}

export const trackAppliedCouponCode = (projectId, projectType, couponCode) => {
  track('Applied Coupon Code', { projectId, projectType, couponCode})
}

export const trackProceededToCheckout = (projectId, projectType) => {
  track('Proceeded to Checkout', { projectId, projectType})
}

export const trackCancelledCheckout = (projectId) => {
  track('Cancelled Checkout', { projectId })
}

export const trackCompletedPurchase = (project) => {
  track('Order Completed', {
    projectId: project.projectId,
    order_id: project.projectId,
    value: project.price,
    total: project.price,
    discount: project.discount,
    coupon: project.coupon_code
  })
}
