import React from 'react'

import styled from '@emotion/styled'
import colors from '../../styles/colors'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0em .5em 1em 0em;
  border-radius: 5px;
  background: ${colors.lightGrey}10;
  line-height: 0;
`

const SectionTitle = styled.h4`
  color: ${colors.lightGrey};
  margin: 1em 0em .5em 0em;
`

const SectionMain = styled.div`
  width: 100%;
  height: 100%;
`


const ProjectSection = ({title, children, className}) => {
  return <Container className={className}>
    <SectionTitle>{title}</SectionTitle>
    <SectionMain>
      {children}
    </SectionMain>
  </Container>
}

export default ProjectSection;
