import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { StepTitle } from './index'
import Loader from 'react-spinners/RotateLoader'
import colors from '../../styles/colors'
import { API } from 'aws-amplify'
import uuidv4 from 'uuid/v4'
import Products from '../../data/catalog'
import { capitalize } from '../../utils/helpers'
import { Select, Slider } from 'antd'
import {
  getFrameOptionsMosaic,
  getPriceDiffMosaic,
  getSizeOptionsMosaic,
  getStartingPriceMosaic,
} from '../../utils/catalog'
import { trackMosaicModifiedParameters } from '../../utils/tracking'
import MaterialHelp from './help/MaterialHelp'
import TileSizeHelp from './help/TileSizeHelp'
import BlendHelp from './help/BlendHelp'
import FrameHelp from './help/FrameHelp'
import SizeHelp from './help/SizeHelp'

const { Option } = Select


const Container = styled.div`
  display: flex;
`

const Instructions = styled.div`
  margin: 0 2em;
  flex: 1;
  height 100%;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 0 0 0;
`

const PreviewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PreviewImage = styled.img`
  max-height: 60vh;
  max-width: 60vw;
`

const PreviewDisclaimer = styled.p`
  font-size: .7em;
  margin: 1em;
`

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`

const OptionTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
  color: ${colors.darkGrey};
  font-weight: 700;
  margin: 0 0;
`

const OptionSelect = styled(Select)`
  margin: 8px 0;
`

const OptionPriceDiff = styled.p`
  color: ${colors.darkPurple};
  float: right;
  margin: 0;
`


const Generate = ({ project, saveMosaic }) => {
  const [photoLoading, setPhotoLoading] = useState(true)
  const [previewVersion, setPreviewVersion] = useState(uuidv4())
  const previewVersionRef = useRef('')
  const [mosaicUrl, setMosaicUrl] = useState('')
  const [blendValue, setBlendValue] = useState(project.blend)
  const [tilesPerInch, setTilesPerInch] = useState(project.tiles_per_inch)
  const imageComponent = photoLoading ? <Loader size={20} color={colors.darkPurple}/> : (
    mosaicUrl ? <>
        <PreviewImage src={mosaicUrl} alt={'Mosaic Preview'}/>
        <PreviewDisclaimer><b>Note:</b> This is a low-resolution preview for quick rendering. The full render will be 10x+
          more detailed! Also, due to the nature of photo printing, the tiles on the outer edge may be trimmed slightly.</PreviewDisclaimer>
      </> :
      <OptionTitle>Unable to generate yet, please complete steps 1 + 2 first!</OptionTitle>
  )

  // Generate new preview on version change
  useEffect(() => {
    previewVersionRef.current = previewVersion
    API.post(
      'photoroni', `/projects/${project.project_id}/mosaic/preview`,
      { body: { version: previewVersion } },
    ).then(() => {
      setPhotoLoading(true)
      let poll = setInterval(() => {
        API.get('photoroni', `/projects/${project.project_id}/mosaic/preview`, {}).then((previewInfo) => {
          // Handler for when new preview is being generated
          if (previewVersion !== previewVersionRef.current) clearInterval(poll)
          // If version returned is expected version, complete
          else if (previewInfo.version === previewVersion) {
            setMosaicUrl(previewInfo.url)
            setPhotoLoading(false)
            clearInterval(poll)
          }
        })
      }, 1000)
    }).catch(() => {
      setPhotoLoading(false)
    })
  }, [previewVersion, project.project_id])

  const generateNew = () => {
    setPreviewVersion(uuidv4())
  }

  const modifyParameter = (parameterName, parameterValue, skipGenerate) => {
    saveMosaic({ [parameterName]: parameterValue }).then(() => {
      if (!skipGenerate) generateNew()
    })
    trackMosaicModifiedParameters(project.project_id, parameterName, parameterValue)
  }

  const sizeOptions = getSizeOptionsMosaic(project.material, project.orientation)
  const frameOptions = getFrameOptionsMosaic(project.material, project.orientation, project.size)

  return <Container>
    <Instructions>
      <StepTitle>Step 3: Generate your mosaic</StepTitle>
      <p>Adjust the settings below to select the product you'd like, and to optimize your mosaic exactly the way
        you want it! Click on the help icons with each setting for more information.</p>
      <OptionsContainer>
        <OptionContainer>
          <OptionTitle>Material <MaterialHelp defaultTab={project.material}/></OptionTitle>
          <OptionSelect
            value={project.material}
            onChange={(value) => modifyParameter('material', value)}
          >
            {Object.keys(Products.mosaic).map((value) => {
              return <Option value={value}>{capitalize(value)}
                <OptionPriceDiff>Starting at ${getStartingPriceMosaic(value, project.orientation)}</OptionPriceDiff>
              </Option>
            })}
          </OptionSelect>
        </OptionContainer>
        <OptionContainer>
          <OptionTitle>Size (inches) <SizeHelp/></OptionTitle>
          <OptionSelect
            value={project.size}
            onChange={(value) => modifyParameter('size', value)}
          >
            {sizeOptions.map((value) => {
              return <Option value={value}>{value.replace('x', ' x ')}
                <OptionPriceDiff>{getPriceDiffMosaic(project.material, project.orientation, project.size, value, project.frame, project.frame)}</OptionPriceDiff>
              </Option>
            })}
          </OptionSelect>
        </OptionContainer>
        <OptionContainer>
          <OptionTitle>Frame <FrameHelp/></OptionTitle>
          <OptionSelect
            value={project.frame}
            disabled={!frameOptions}
            placeholder={!frameOptions ? 'Not Available' : ''}
            onChange={(value) => modifyParameter('frame', value, true)}
          >
            {frameOptions ? frameOptions.map((value) => {
              return <Option value={value}>{capitalize(value)}
                <OptionPriceDiff>{getPriceDiffMosaic(project.material, project.orientation, project.size, project.size, project.frame, value)}</OptionPriceDiff>
              </Option>
            }) : <></>}
          </OptionSelect>
        </OptionContainer>
        <OptionContainer>
          <OptionTitle>Blend <BlendHelp/></OptionTitle>
          <Slider
            onChange={(value) => setBlendValue(value)}
            onAfterChange={(value) => modifyParameter('blend', value)}
            value={blendValue}
            min={1}
            max={7}
            marks={{
              1: 'Strong Tiles',
              7: 'Strong Background',
            }}
            tooltipVisible={false}
          />
        </OptionContainer>
        <OptionContainer>
          <OptionTitle>Tiles per Inch <TileSizeHelp/></OptionTitle>
          <Slider
            onChange={(value) => setTilesPerInch(value)}
            onAfterChange={(value) => modifyParameter('tiles_per_inch', value)}
            value={tilesPerInch}
            min={1}
            max={3}
            marks={{
              1: '1',
              2: '2',
              3: '3',
            }}
            tooltipVisible={false}
          />
        </OptionContainer>
      </OptionsContainer>
    </Instructions>
    <PreviewContainer>
      {imageComponent}
    </PreviewContainer>
  </Container>
}

export default Generate
