import React from 'react'
import styled from '@emotion/styled'
import colors from '../../styles/colors'


const ResponsiveSvg = styled.svg`
  cursor: pointer;
`

const Save = ({ style, className, onClick }) => (
  <ResponsiveSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' css={style} onClick={onClick}
                 className={className}>
    <path
      d='M352,48H160a48,48,0,0,0-48,48V464L256,336,400,464V96A48,48,0,0,0,352,48Z'
      style={{
        fill: colors.lightPurple,
        stroke: colors.darkPurple,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '32px',
      }}
    />
  </ResponsiveSvg>
)

export default Save
