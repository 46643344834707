import css from "@emotion/css";
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';

const globalStyles = css`
    html,
    body,
    #root {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100%;
    }
    
    html {
      @media(max-width: ${dimensions.maxwidthTablet}px) {
          overflow-x: hidden !important;
      }
    }

    body {
        width: 100%;
        margin: 0 auto;
        font-size: 16px;
        line-height: 1.5;
        color: ${colors.darkGrey};
        -webkit-font-smoothing: antialiased;

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            font-size: 14px;
        }
        
        @media(max-width: ${dimensions.maxwidthTablet}px) {
            overflow-x: hidden !important;
        }

        * {
            box-sizing: border-box;
        }
    }

    /*
    A workaround for forcing accessibility wrappers
    to have a 100% height.
    Reach Router issue here: https: //github.com/reach/router/issues/63
    */
    #___gatsby,
    div[role="group"][tabindex] {
        height: 100%;
        min-height: 100% !important;
    }
`

export default globalStyles;