import React from 'react'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  }
  return stripePromise;
}

export default ({ children }) => {
  return (
    <Elements stripe={getStripe()}>
      {children}
    </Elements>
  )
}