import React from 'react'
import colors from '../../../styles/colors'
import styled from '@emotion/styled'
import HelpModal from '../../utils/HelpModal'


const HelpTable = styled.table`
  th {
    white-space: nowrap;
    padding: 5px;
  }
  
  td {
    border-top: 1px solid black;
    padding: 5px;
  }
`

const CenterNoWrap = styled.td`
  text-align: center;
  white-space: nowrap;
`


export default () => {
  return <HelpModal
    color={colors.lightPurple}
    title={'Tiles per Inch'}
    width={'50%'}
    content={
      <p>
        This setting adjusts how small or large the tiles are. The best value here depends on what you care
        about most in your mosaic.
        <HelpTable>
          <tr>
            <th>Tiles per Inch</th>
            <th>Tile Size</th>
            <th>Tile Clarity</th>
            <th>Mosaic Effect (Background clarity)</th>
          </tr>
          <tr>
            <CenterNoWrap>1</CenterNoWrap>
            <CenterNoWrap>1" x 1"</CenterNoWrap>
            <td>Highly detailed, can see individual tile photos very well</td>
            <td>To maintain a good mosaic effect at this size, a 24 x 36 size or larger is recommended</td>
          </tr>
          <tr>
            <CenterNoWrap>2</CenterNoWrap>
            <CenterNoWrap>1/2" x 1/2"</CenterNoWrap>
            <td>Detailed, this is a good setting to achieve a balance between tile clarity and mosaic effect
            </td>
            <td>To maintain a good mosaic effect at this size, a 16 x 24 size or larger is recommended</td>
          </tr>
          <tr>
            <CenterNoWrap>3</CenterNoWrap>
            <CenterNoWrap>1/3" x 1/3"</CenterNoWrap>
            <td>Can still make out photos, but details will be lost.
            </td>
            <td>You'll have a great mosaic effect at all sizes with this setting, although combining this
              setting with a larger size will give an excellent mosaic effect!
            </td>
          </tr>
        </HelpTable>
      </p>
    }
  />
}
