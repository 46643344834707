import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import styled from '@emotion/styled'
import { Input, Spin } from 'antd'
import { fromCents } from '../../utils/helpers'
import { Checkmark } from 'react-checkmark'
import colors from '../../styles/colors'
import Download from '../svgs/Download'
import Loader from '../utils/Loader'
import useDelay from '../utils/delay'


const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Thanks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  h1 {
    margin: 0 0 0 15px;
    color: ${colors.darkGrey};
    font-size: 2em;
    font-weight: 650;
  }
`

const LowerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-around;
`

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 600;
  margin: 1em 0;
`

const PreviewImage = styled.img`
  max-height: 400px;
`

const EmailSignupContainer = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  max-width: 80%;
`

const EmailSignupRow = styled.div`
  display: flex;
  align-items: center;
`

const EmailInput = styled(Input)`
  border-radius: 5px;
  border-width: 3px;
  margin: 10px 0;
  flex: 4;
  font-size: 1em;
`

const EmailButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightPurple};
  color: ${colors.offWhite};
  border: none;
  border-radius: 5px;
  height: 100%;
  flex: 1;
  font-weight: 550;
  margin: 0 0 0 10px;
  cursor: pointer;
  line-height: 2;
  
  &:hover {
    background: ${colors.darkPurple}D0;
    color: ${colors.offWhite};
  }
  
  &:disabled {
    background: ${colors.darkGrey}A0;
    cursor: inherit;
  }
`

const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DownloadLabel = styled.div`
  font-weight: 650;
  font-size: 1.3em;
  color: ${colors.darkGrey};
`

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.darkPurple};
  border: none;
  font-size: 1.3em;
  color: ${colors.offWhite};
  font-weight: 700;
  border-radius: 5px;
  width: 20vw;
  min-width: 200px;
  line-height: 2.5;
  margin: 1em 0 0 0;
  cursor: pointer;
  
  &:hover {
    background: ${colors.darkPurple}D0;
    color: ${colors.offWhite};
  }
  
  &:focus {
    outline: 0;
  }
`

const DownloadIcon = styled(Download)`
  height: 25px;
  margin: 0 5px;
`

const Spinner = styled(Spin)`
  margin: 1em 0 0 0;
`


export default ({ project }) => {
  const [orderDetails, setOrderDetails] = useState(null)
  const [email, setEmail] = useState('')
  const [signedUp, setSignedUp] = useState(false)
  const [delayComplete] = useDelay(10000)
  const isDownload = project.is_download

  useEffect(() => {
    let poll = setInterval(() => {
      API.get('photoroni', `/projects/${project.project_id}/details`, {}).then((details) => {
        setOrderDetails(details)
        if (!isDownload || details.download_url) clearInterval(poll)
      })
    }, 1000)
  }, [project.project_id, isDownload])

  const emailSignUp = () => {
    API.post('photoroni', `/actions/emailsignup`, { body: { email, source: 'post_checkout' } }).then(() => {
      setEmail('Thanks!')
      setSignedUp(true)
    })
  }

  return orderDetails ? <OrderContainer>
    <Thanks><Checkmark color={colors.successGreen}/><h1>Thank you for your order!</h1></Thanks>
    <LowerContainer>
      <Left>
        <OrderDetails>
          <span>{orderDetails.name}</span>
          <span>{fromCents(orderDetails.price)}</span>
        </OrderDetails>
        <PreviewImage src={orderDetails.preview_url} alt={'order preview'}/>
      </Left>
      <Right>
        <EmailSignupContainer>
          {isDownload ? <>Your full resolution download is almost ready!</> :
            <>Getting started on your order now! We'll send you shipping details as soon as your order is complete and
              headed your way. </>}
          In the meantime, if you haven't done so already, take this opportunity to sign up for our newsletter and
          receive a coupon towards your next purchase, as well ongoing special offers and new product releases!
          <EmailSignupRow>
            <EmailInput placeholder={'Email'} type={'email'} onChange={(e) => setEmail(e.target.value)} value={email}
                        autocomplete={'email'} disabled={signedUp}/>
            <EmailButton onClick={() => emailSignUp()} disabled={signedUp}>Sign Up</EmailButton>
          </EmailSignupRow>
        </EmailSignupContainer>
        {
          isDownload ? <DownloadButtonContainer>
            <DownloadLabel>Full Resolution Download</DownloadLabel>
            {
              orderDetails.download_url && delayComplete ?
                <DownloadButton href={orderDetails.download_url} download={'mosaic.png'}><DownloadIcon/>Download
                  Now</DownloadButton> : <Spinner tip={'Preparing now...'} size={'large'}/>
            }
          </DownloadButtonContainer> : <></>
        }
      </Right>
    </LowerContainer>
  </OrderContainer> : <Loader/>
}
