import React, { useEffect } from 'react'
import { API } from 'aws-amplify'
import { navigate } from 'gatsby-link'
import Loader from '../components/utils/Loader'


const NewProject = ({ projectType }) => {
  useEffect(() => {
    // Create project
    API.post('photoroni', `/projects`, {
      body: { project_type: projectType }
    }).then((project) => {
      navigate(`/projects/${project.project_id}`)
    }).catch(() => {
      // Redirect to home if failed to load
      navigate('/')
    })
  }, [projectType])

  return <Loader/>
}

export default NewProject
