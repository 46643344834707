import React, { useEffect, useState } from 'react'
import Loader from '../components/utils/Loader'
import { API } from 'aws-amplify'
import { navigate } from 'gatsby-link'
import styled from '@emotion/styled'
import Logo from '../components/svgs/Logo'
import { trackCancelledCheckout, trackCompletedPurchase } from '../utils/tracking'
import ProjectOrdered from '../components/project/ProjectOrdered'
import ProjectEditor from '../components/project/ProjectEditor'

const HeaderContainer = styled.div`
    padding-top: 2em;
    display: flex;
    justify-content: center;
`

const HeaderContent = styled.div`
    display: flex;
`

const SmallerLogo = styled(Logo)`
  max-width: 150px;
`


export default ({ projectId, location }) => {
  const [project, setProject] = useState(null)

  // If at checkout cancel URL, capture event and redirect to regular project
  useEffect(() => {
    if (location.pathname.includes('checkoutCancel')) {
      trackCancelledCheckout(projectId)
      navigate(`/projects/${projectId}`)
    }
  }, [location, projectId, project])

  // If at checkout success URL, capture event and redirect to order page
  useEffect(() => {
    if (location.pathname.includes('checkoutSuccess' && project)) {
      trackCompletedPurchase(project)
      navigate(`/projects/${projectId}`)
    }
  }, [location, projectId, project])

  useEffect(() => {
    // Get project from API
    API.get('photoroni', `/projects/${projectId}`, {}).then((project) => {
      setProject(project)
    }).catch(() => {
      // Redirect to root if failed to load
      navigate('/')
    })
  }, [projectId])

  // Set projectId in localStorage for Uppy components that need to initialize outside of render
  localStorage.setItem('projectId', projectId)

  return !project ? <Loader/> : <>
    <HeaderContainer>
      <HeaderContent>
        <a href="https://www.photoroni.com">
          <SmallerLogo/>
        </a>
      </HeaderContent>
    </HeaderContainer>
    {project.ordered_at ? <ProjectOrdered project={project}/> : <ProjectEditor project={project}/>}
  </>
}
