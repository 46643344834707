import React, { useState } from 'react'
import styled from '@emotion/styled'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Delete from '../svgs/Delete'
import { API } from 'aws-amplify'


const TileContainer = styled.div`
  position: relative;
  margin: 1%;
  
  .ReactCrop__crop-selection {
    border-image-source: none;
  }
`

const TileDelete = styled(Delete)`
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  margin: -12px;
`


const MosaicTileCrop = ({ projectId, backgroundUrl, tileKey, initialCrop }) => {
  const [crop, setCrop] = useState(initialCrop)
  const [isDeleted, setIsDeleted] = useState(false)

  const deleteTile = (tile_key) => {
    // Make API request to remove tile
    API.del('photoroni', `/projects/${projectId}/mosaic/tiles`, {
      body: { tile_key },
    })
    // Set as deleted
    setIsDeleted(true)
  }

  const saveCrop = () => {
    API.post('photoroni', `/projects/${projectId}/mosaic/tiles/crop`, {
      body: {tile_key: tileKey, crop}
    })
  }

  return isDeleted ? <></> : <TileContainer>
    <ReactCrop
      src={backgroundUrl}
      crop={crop}
      onChange={(crop, percentCrop) => setCrop(percentCrop)}
      keepSelection={true}
      onDragEnd={() => saveCrop()}
    />
    <TileDelete onClick={() => deleteTile(tileKey)}/>
  </TileContainer>
}

export default MosaicTileCrop
