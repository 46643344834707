import React from 'react'
import colors from '../../../styles/colors'
import styled from '@emotion/styled'
import HelpModal from '../../utils/HelpModal'


const HelpTable = styled.table`  
  th {
    white-space: nowrap;
    padding: 5px;
    text-align: center;
  }
  
  td {
    border-top: 1px solid black;
    padding: 5px;
  }
`

const CenterNoWrap = styled.td`
  text-align: center;
  white-space: nowrap;
`


export default () => {
  return <HelpModal
    color={colors.lightPurple}
    title={'Size'}
    width={'50%'}
    content={
      <p>
        This setting adjusts the size of your mosaic. Please read the notes for the available materials below
        to better understand our selection. Feel free to <a
        href="https://photoroni.freshdesk.com/support/tickets/new" target="_blank" rel="noopener noreferrer">contact
        us</a> for custom sizing options if you're not able to find what you're looking for!
        <HelpTable>
          <tr>
            <th>Material</th>
            <th>Notes</th>
          </tr>
          <tr>
            <CenterNoWrap>Acrylic</CenterNoWrap>
            <td>For square mosaic, only available in 12" x 12". If you're looking for an acrylic print of a
              larger size, you can go back to step 1 and select either portrait or landscape.
            </td>
          </tr>
          <tr>
            <CenterNoWrap>Canvas</CenterNoWrap>
            <td>Offers the largest square mosaic options, 30" x 30" and 40" x 40".</td>
          </tr>
          <tr>
            <CenterNoWrap>Metal</CenterNoWrap>
            <td>For square mosaic, only available in 12" x 12". If you're looking for a metal print of a
              larger size, you can go back to step 1 and select either portrait or landscape.
            </td>
          </tr>
          <tr>
            <CenterNoWrap>Paper</CenterNoWrap>
            <td>Standard size offerings for all orientations (square, landscape, portrait).</td>
          </tr>
          <tr>
            <CenterNoWrap>Download</CenterNoWrap>
            <td>The size selected will determine the size you're able to print your file with at 300DPI (dots per
              inch).
            </td>
          </tr>
        </HelpTable>
      </p>
    }
  />
}
