import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import globalStyles from 'styles/global'
import dimensions from 'styles/dimensions'
import 'styles/fonts.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const LayoutContainer = styled.div`
    max-width: 95%;
    padding-left: ${dimensions.paddingHorizontalDesktop}em;
    padding-right: ${dimensions.paddingHorizontalDesktop}em;
    margin: 0 auto;

    @media(max-width: ${dimensions.maxwidthTablet}px) {    
        padding-left: ${dimensions.paddingHorizontalTablet}em;
        padding-right: ${dimensions.paddingHorizontalTablet}em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        padding-left: ${dimensions.paddingHorizontalMobile}em;
        padding-right: ${dimensions.paddingHorizontalMobile}em;
    }
    
    .Toastify__toast {
      border-radius: 5px;
      font-weight: 550;
      text-align: center;
    }
`;


const Layout = ({ children }) => {
  return <LayoutContainer className="div">
    <Global styles={[globalStyles]}/>
    <div className="Layout">
      <main>
        {children}
      </main>
    </div>
    <ToastContainer/>
  </LayoutContainer>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
