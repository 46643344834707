import React from 'react'

import styled from '@emotion/styled'
import ProjectSection from './ProjectSection'
import dimensions from '../../styles/dimensions'


const SplitSelector = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1em;
  
  @media(max-width: ${dimensions.maxwidthTablet}px) {
    flex-direction: column;
  }
`

const SplitColumnMobileOnly = styled(SplitSelector)`
  @media(max-width: ${dimensions.maxwidthTablet}px) {
    flex-direction: row;
  }
  
  @media(max-width: ${dimensions.maxwidthMobile}px) {
    flex-direction: column;
  }
`

const Description = styled.p`
  text-align: center;
  margin: 1em 3em;
`


const ProjectSplitSelector = ({ title, description, children, className, keepTabletColumns }) => {
  const Selector = keepTabletColumns ? SplitColumnMobileOnly : SplitSelector

  return <ProjectSection title={title} className={className}>
    <Description>{description}</Description>
    <Selector>
      {children}
    </Selector>
  </ProjectSection>
}

export default ProjectSplitSelector
