import React from 'react'
import { Redirect, Router } from '@reach/router'
import Project from '../client-pages/project'
import Helmet from 'react-helmet'
import Amplify from 'aws-amplify'
import amplifyConfig from '../utils/amplifyConfig'
import NewProject from '../client-pages/newProject'
import Layout from '../components/layouts/Layout'
import Stripe from '../components/utils/Stripe'

Amplify.configure(amplifyConfig)


const Projects = () => {
  return (
    <>
      <Helmet title='Photoroni'>
        <script async type="text/javascript" src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=MBWSSW"/>
      </Helmet>
      <Layout>
        <Stripe>
          <Router basepath="/projects">
            <Redirect from="/" to="/projects/new/mosaic"/>
            <NewProject path="/new/:projectType"/>
            <Project path="/:projectId"/>
            <Project path="/:projectId/checkoutCancel"/>
            <Project path="/:projectId/checkoutSuccess"/>
          </Router>
        </Stripe>
      </Layout>
    </>
  )
}

export default Projects
