import styled from '@emotion/styled'
import colors from './colors'

export const ActionButton = styled.button`
  background: ${colors.darkGrey};
  border: none;
  font-size: 1em;
  color: ${colors.lightLightPurple};
  font-weight: 700;
  border-radius: 5px;
  width: 100%;
  line-height: 2.5;
  margin: .5em;
  cursor: pointer;
  
  &:hover {
    background: ${colors.darkGrey}D0;
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    background: ${colors.lightGrey};
    cursor: inherit;
  }
`

export const NavButton = styled.button`
  background: ${colors.lightPurple};
  border: none;
  font-size: 1em;
  color: ${colors.offWhite};
  font-weight: 700;
  border-radius: 5px;
  width: 100%;
  line-height: 2.5;
  margin: .5em;
  cursor: pointer;
  
  &:hover {
    background: ${colors.darkPurple}D0;
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    background: ${colors.lightGrey};
    cursor: inherit;
  }
`
