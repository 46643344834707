import React from 'react'

import styled from '@emotion/styled'
import colors from '../../styles/colors'
import { css } from '@emotion/core'
import dimensions from '../../styles/dimensions'


export const Orientations = {
  portrait: 'portrait',
  landscape: 'landscape',
  square: 'square'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  
  @media(max-width: ${dimensions.maxwidthTablet}px) {
    margin: 5px;
  }
`

const Box = styled.div`
  background: ${colors.lightGrey}50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background: ${colors.lightGrey}70;
  }
  
  @media(max-width: ${dimensions.maxwidthMobile}px) {
    width: 90%;
    height: 10%;
  }
`

const BoxSelected = styled(Box)`
  background: ${colors.lightGrey}90;
  border: 5px solid ${colors.darkPurple};
  cursor: inherit;
  
  &:hover {
    background: ${colors.lightGrey}90;
  }
`

const Label = styled.p`
  color: ${colors.darkGrey};
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  line-height: 0;
`


const ProjectOrientation = ({ label, widthRatio, heightRatio, selected, onClick }) => {
  const B = selected ? BoxSelected : Box

  return <Container>
    <B css={css`
    width: ${widthRatio * 5}em;
    height: ${heightRatio * 5}em;
  `} onClick={onClick}>
      <Label>{label}</Label>
    </B>
  </Container>
}

export default ProjectOrientation
