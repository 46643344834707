import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Background from './Background'
import colors from '../../styles/colors'
import { API } from 'aws-amplify'
import Tiles from './Tiles'
import Generate from './Generate'
import { NavButton } from '../../styles/shared'
import 'antd/dist/antd.css'
import { Input, Steps } from 'antd'
import Cart from '../svgs/Cart'
import { toast } from 'react-toastify'
import { fromCents } from '../../utils/helpers'
import { trackAppliedCouponCode, trackMosaicStep } from '../../utils/tracking'

const { Step } = Steps


const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepTitle = styled.p`
  color: ${colors.darkGrey};
  font-size: 1.2em;
  font-weight: 800;
`

const StepsContainer = styled.div`
  display: flex;
  align-self: center;
  width: 85%;
  margin: .5em 1em;
`

const ContentContainer = styled.div`
  margin: 1em;
`

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 1em 0 1em;
`

const NavElement = styled(NavButton)`
  width: 20%;
`

const NavCheckout = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`

const CheckoutButton = styled(NavButton)`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.darkPurple};
  color: ${colors.lightLightPurple};
`

const CouponCode = styled(Input)`
  flex: 2;
  font-size: 1.3em;
  border-radius: 5px 0 0 5px;
`

const CouponApply = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightPurple};
  color: ${colors.offWhite};
  border: none;
  border-radius: 0 5px 5px 0;
  flex: 1;
  font-weight: 650;
  cursor: pointer;
  margin: 0 15px 0 0;
  line-height: 2.5em;
  
  &:hover {
    background: ${colors.darkPurple}D0;
    color: ${colors.offWhite};
  }
`

const CartIcon = styled(Cart)`
  height: 30px;
  margin: 0 5px;
`


const STEPS = {
  0: Background,
  1: Tiles,
  2: Generate,
}


const Mosaic = ({ initialProject, checkout }) => {
  const [project, setProject] = useState(initialProject)
  const [couponCode, setCouponCode] = useState('')
  const [step, setStep] = useState(0)

  const saveMosaic = (data) => {
    setProject({ ...project, ...data })
    return API.put('photoroni', `/projects/${project.project_id}`, {
      body: {
        project_type: 'mosaic',
        data: data,
      },
    }).then((res) => setProject(res))
  }

  const toggleCouponCode = () => {
    project.coupon_code ?
      API.del('photoroni', `/projects/${project.project_id}/coupon`, { body: {} }).then((res) => {
        setProject(res)
        setCouponCode('')
      }) :
      API.post('photoroni', `/projects/${project.project_id}/coupon`, {
        body: { coupon_code: couponCode },
      }).then((res) => {
        setProject(res)
        trackAppliedCouponCode(project.project_id, 'mosaic', couponCode)
      })
        .catch((error) => {
          toast.error(error.response.data.error || 'Coupon failed to apply', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          })
        })
  }

  useEffect(() => {
    trackMosaicStep(project.project_id, step + 1)
  }, [project.project_id, step])

  return <Container>
    <StepsContainer>
      <Steps current={step}>
        <Step title={'Background'}/>
        <Step title={'Tiles'}/>
        <Step title={'Generate'}/>
      </Steps>
    </StepsContainer>
    <ContentContainer>
      {React.createElement(STEPS[step], { project, saveMosaic, checkout })}
    </ContentContainer>
    <NavBar>
      {step !== 0 ? <NavElement onClick={() => setStep(step - 1)}>Previous Step</NavElement> : <div/>}
      {step !== 2 ? <NavElement onClick={() => setStep(step + 1)}>Next Step</NavElement> :
        <NavCheckout>
          <CouponCode placeholder={'Coupon Code'} value={project.coupon_code || couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      disabled={!!project.coupon_code}/>
          <CouponApply onClick={() => toggleCouponCode()} disabled={!couponCode && !project.coupon_code}>{project.coupon_code ? 'Unapply' : 'Apply'}</CouponApply>
          <CheckoutButton onClick={() => checkout()} disabled={!project.num_tiles}><CartIcon/>
            Continue to Checkout - {' '} {fromCents(project.price)}
          </CheckoutButton>
        </NavCheckout>
      }
    </NavBar>
  </Container>
}

export default Mosaic
