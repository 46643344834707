import React from 'react'
import styled from '@emotion/styled'
import colors from '../../styles/colors'


const ResponsiveSvg = styled.svg`

`

const Upload = ({ style, className, onClick, title }) => (
  <ResponsiveSvg viewBox='0 0 512 512' xmlns="http://www.w3.org/2000/svg" css={style} onClick={onClick}
                 className={className}>
    <title>{title}</title>
    <path
      d='M320,367.79h76c55,0,100-29.21,100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69,0-113.44,45.79-128,91.2-60,5.7-112,43.88-112,106.4s54,106.4,120,106.4h56'
      style={{
        fill: 'none',
        stroke: colors.lightPurple,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '32px',
      }}/>
    <polyline points='320 255.79 256 191.79 192 255.79'
              style={{
                fill: 'none',
                stroke: colors.lightPurple,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '32px',
              }}/>
    <line x1='256' y1='448.21' x2='256' y2='207.79'
          style={{
            fill: 'none',
            stroke: colors.lightPurple,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}/>
  </ResponsiveSvg>

)

export default Upload
