import React from 'react'
import styled from '@emotion/styled'
import { Tabs } from 'antd'
import colors from '../../../styles/colors'
import HelpModal from '../../utils/HelpModal'
import { graphql, useStaticQuery } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const { TabPane } = Tabs

const CarouselContainer = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width:100%;
    max-height:100%;
    width: auto !important;
  }
`

const Description = styled.div`

`

const ProductPhotosCarousel = ({ urls, printType }) => {
  return <Carousel showArrows={true}>
    {urls[printType] ? urls[printType].map((url) => {
      return <CarouselContainer key={url}>
        <img src={url} alt={`${printType} example`}/>
      </CarouselContainer>
    }) : <></>}
  </Carousel>
}


export default ({ defaultTab }) => {
  const data = useStaticQuery(graphql`
      query CloudinaryMaterialsImages {
          allCloudinaryMedia(filter: {public_id: {regex: "/gatsby-source\/print-materials\/"}}, sort: {fields: public_id}) {
              nodes {
                  secure_url
              }
          }
      }
  `)
  const urls = data ? data.allCloudinaryMedia.nodes.reduce((acc, node) => {
    for (let material of ['acrylic', 'metal', 'canvas', 'paper']) {
      if (node.secure_url.includes(material)) {
        if (acc[material]) acc[material].push(node.secure_url.replace('f_auto', 'h_700'))
        else acc[material] = [node.secure_url.replace('f_auto', 'h_700')]
        break
      }
    }
    return acc
  }, {}) : {}

  return <HelpModal
    color={colors.lightPurple}
    title={'Material'}
    width={'50%'}
    content={
      <Tabs defaultActiveKey={defaultTab}>
        <TabPane tab="Acrylic" key="acrylic">
          <Description>
            <b>Material</b>
            <ul>
              <li>Clear acrylic with polished edges</li>
              <li>0.236 inch (5.99 mm) thick</li>
            </ul>
            <b>Print</b>
            <ul>
              <li>Printed with latex inks for extreme color vibrancy and long-lasting durability</li>
            </ul>
            <b>Mounting</b>
            <ul>
              <li>Ready to hang</li>
              <li>Pre-installed 1.25 inch (31.75 mm) wood mounting on backside of print for hanging</li>
            </ul>
            <b>Care</b>
            <ul>
              <li>Clean with non-abrasive cleaners, glass cleaners, or alcohol-based cleaners</li>
            </ul>
          </Description>
          <ProductPhotosCarousel urls={urls} printType={'acrylic'}/>
        </TabPane>
        <TabPane tab="Canvas" key="canvas">
          <Description>
            <b>Material</b>
            <ul>
              <li>Genuine artist quality canvas</li>
              <li>Poly cotton blend</li>
              <li>Chemistry perfect gloss finish</li>
              <li>Scratch, crack, & warp resistant</li>
            </ul>
            <b>Wrap (what shows on sizes of canvas)</b>
            <ul>
              <li>No Frame: Mirrored. The sides of your image will be sampled and
                mirrored so that the mosaic effect continues around the edges but you don't lose any of your main image.
              </li>
              <li>Frame: Solid black. The sides of your image will be solid black, so that the front pops more against the black frame.
              </li>
            </ul>
            <b>Print</b>
            <ul>
              <li>State of the art printing technology for sharp photographic reproduction and color fidelity</li>
              <li>UL Certified GREENGUARD GOLD Ink</li>
              <li>Water resistant</li>
              <li>Fade resistant</li>
              <li>Anti-yellowing</li>
            </ul>
            <b>Stretcher Bar</b>
            <ul>
              <li>FSC Certified from sustainable forests</li>
              <li>Knot free</li>
              <li>Finger jointed for strength</li>
              <li>Sap free</li>
              <li>Kiln dried</li>
              <li>Warp free - will not bow</li>
            </ul>
            <b>Mounting</b>
            <ul>
              <li>Ready to hang - pre-installed sawtooth hanging hardware</li>
              <li>Rubber bumpers - protects wall surface and keeps print straight on the wall</li>
            </ul>
            <b>Care</b>
            <ul>
              <li>Clean with a dry cloth when needed</li>
            </ul>
            <b>Frame (Not included in base price)</b>
            <ul>
              <li>To add frame, select option in 'Frame' dropdown</li>
              <li>Roma Moulding - hand crafted in Italy</li>
              <li>5 times sanded & finished without toxins or chipping</li>
              <li>100% real pine wood</li>
              <li>Professional matte black finish</li>
            </ul>
          </Description>
          <ProductPhotosCarousel urls={urls} printType={'canvas'}/>
        </TabPane>
        <TabPane tab="Metal" key="metal">
          <b>Material</b>
          <ul>
            <li>Matte finish</li>
            <li>White coated aluminum</li>
            <li>0.04" thick</li>
            <li>Ideal for indoor & outdoor applications</li>
            <li>Scratch resistant</li>
            <li>Crack resistant</li>
          </ul>
          <b>Print</b>
          <ul>
            <li>Printed with latex inks for extreme color vibrancy and long-lasting durability</li>
            <li>UV inhibitors for outdoor use</li>
            <li>Fade resistant (including in direct sunlight for decades)</li>
            <li>Water resistant</li>
            <li>Anti-yellowing</li>
          </ul>
          <b>Mounting</b>
          <ul>
            <li>Ready to hang</li>
            <li>Pre-installed 1.25" wood mounting on backside of print for hanging</li>
          </ul>
          <b>Care</b>
          <ul>
            <li>Clean with non-abrasive or alcohol-based cleaners</li>
          </ul>
          <ProductPhotosCarousel urls={urls} printType={'metal'}/>
        </TabPane>
        <TabPane tab="Paper" key="paper">
          <b>Material</b>
          <ul>
            <li>Bright, white photo paper with a satin finish</li>
            <li>Weight - 260g/m2</li>
            <li>Thickness - 10 mil</li>
            <li>Brightness - 93.87%</li>
            <li>Opacity - 96.44</li>
            <li>Full sharp color fidelity</li>
          </ul>

          <b>Print</b>
          <ul>
            <li>State of the art printing technology which results in sharp photographic reproduction and color
              fidelity
            </li>
            <li>UL certified GREENGUARD GOLD ink</li>
          </ul>

          <b>With Frame (not included in base price)</b>
          <ul>
            <li><b>Framing</b>
              <ul>
                <li>100% real wood frame with a matte black or white enamel finish</li>
                <li>Shatter proof plexi glass</li>
                <li>Depth of frame is 3/4"</li>
              </ul>
            </li>
            <li><b>Mounting</b>
              <ul>
                <li>Ready to hang - pre-installed hanging hardware</li>
                <li>Rubber bumpers - protects wall surface and keeps print straight on the wall</li>
              </ul>
            </li>
            <li><b>Package</b>
              <ul>
                <li>Each framed print is sealed in a plastic bag secured to a single cardboard insert</li>
              </ul>
            </li>
            <li><b>Care</b>
              <ul>
                <li>Clean framed prints with a slightly wet cloth when needed</li>
              </ul>
            </li>
          </ul>

          <b>With No Frame</b>
          <ul>
            <li><b>Package</b>
              <ul>
                <li>Each unframed print is sealed in an archival plastic bag & shipped in an envelope or tube</li>
              </ul>
            </li>
            <li><b>Care</b>
              <ul>
                <li>Due to the absorptive nature of the coating, avoid touching the coated surface</li>
                <li>Cotton gloves are recommended for handling unframed prints</li>
              </ul>
            </li>
          </ul>
          <ProductPhotosCarousel urls={urls} printType={'paper'}/>
        </TabPane>
        <TabPane tab="Download" key="download">
          Receive a high resolution downloadable PNG file of your mosaic, printable to 300 DPI (dots per inch) for your
          selected size. File available for download immediately after checkout.
        </TabPane>
      </Tabs>
    }
  />
}
