import { useState } from 'react'

const useDelay = (ms) => {
  const [delayComplete, setDelayComplete] = useState(false)

  setTimeout(() => {
    setDelayComplete(true)
  }, ms)

  return [delayComplete]
}

export default useDelay
