import React from 'react'
import styled from '@emotion/styled'
import colors from '../../styles/colors'


const ResponsiveSvg = styled.svg`
  cursor: pointer;
  
  &:hover path, &:hover line {
    stroke-width: 36 !important;
  }
  &:hover path {
    fill: ${colors.red} !important;
  }
`


const Delete = ({ style, onClick, title, className }) => (
  <ResponsiveSvg viewBox='0 0 512 512' xmlns="http://www.w3.org/2000/svg" css={style} onClick={onClick} className={className}>
    <title>{title}</title>
    <path d='M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z'
          style={{ fill: colors.lightPurple, stroke: 'white', strokeMiterLimit: 10, strokeWidth: '0px' }}/>
    <line x1='320' y1='320' x2='192' y2='192'
          style={{
            fill: 'none',
            stroke: 'white',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}/>
    <line x1='192' y1='320' x2='320' y2='192'
          style={{
            fill: 'none',
            stroke: 'white',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}/>
  </ResponsiveSvg>
)

export default Delete
